import React from "react"
import { Link, graphql } from "gatsby"

import {
  MdArrowBack,
  MdSupervisorAccount,
  MdCheck,
  MdArrowForward,
  MdList,
  MdAssignment
} from 'react-icons/md';


import SEO from "../components/seo"
import Layout from "../components/layout"

const CCTV = ({data: {image1,cctv }}) => {


    let imgProd = cctv.childImageSharp.fluid.src;

    return (
        <Layout changeHeader={2}>
            <SEO title={`Seguridad CCTV`} />

            <section className="hero-wrap hero-wrap-2 mb-2" style={{backgroundImage: `url(${image1.childImageSharp.fluid.src})`}} data-stellar-background-ratio="0.5">
                <div className="overlay"></div>
                <div className="container">
                    <div className="row no-gutters slider-text align-items-center justify-content-center">
                    <div className="col-md-1" data-sal="slide-right" data-sal-duration="2000" data-sal-easing="ease">
                        <Link to ="/#services-section" className="icon ml-5 btn btn-primary" title="Regresar">
                            <MdArrowBack
                                color="#fff"
                                size="2.3em"
                            /> 
                        </Link>
                        
                    </div>
                    <div className="col-md-11 text-center">
                        <h1 className="mb-2 bread" data-sal="slide-left" data-sal-duration="2000" data-sal-easing="ease">Seguridad CCTV </h1>
                        <p className="breadcrumbs"><span className="mr-2">
                            
                                <Link  className="" to="/#services-section">Servicios</Link> > </span> <span>Seguridad CCTV</span>
                            </p>
                    </div>
                    </div>
                </div>
            </section>
            <section className="text-justify">
            
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6 col-12">
                            <div className="sidebar-box" data-sal="slide-left" data-sal-duration="1500" data-sal-easing="ease">
                                <h3 className="text-center">Seguridad CCTV</h3>

                                <p>&nbsp;&nbsp;&nbsp;&nbsp;CCTV es una sigla en inglés “closed circuit televisión” que traducido al español es “circuito cerrado de televisión”, consiste en una o más cámaras de vigilancias conectadas a uno o más monitores de video o televisores que reproducen las imágenes transmitidas por las cámaras. Las imágenes vistas por la cámara se transmiten por cables coaxiales o una red inalámbrica que se al monitor que se utiliza para transmitir las imágenes.</p>

                                <h3 className="text-center">¿Por qué es importante?</h3>
                                <p>&nbsp;&nbsp;&nbsp;&nbsp;Las cámaras CCTV (circuito cerrado de televisión) son dispositivos modernos que controlan la presencia y las actividades de personas que viven en sus proximidades. Estas cámaras suelen formar parte de sistemas de seguridad en locales comerciales, universidades, restaurantes, hogares, entre otros, debido a su capacidad de atrapar a los delincuentes en vivo cuando comenten un crimen; y sirven como prueba legal de evidencia. De ahí su importancia.</p>
                            </div>
                        </div>

                        <div className="col-md-6 col-12 p-md-7 mt-5" data-sal="slide-right" data-sal-duration="1500" data-sal-easing="ease" >
                            <img src={imgProd} alt="img project" className="img-fluid rounded mb-1" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="ftco-section contact-section ftco-no-pb" id="contact-section" style={{padding: '2em 0'}}>
              <div className="container-fluid mb-4">
                <div className="row justify-content-center mb-1 pb-3">
                  <div className="col-md-7 heading-section text-center " data-sal="slide-up" data-sal-duration="1500" data-sal-easing="ease">
                    <h3 className="mb-4">Pasos</h3>
                  </div>
                </div>
                <div className="row d-flex contact-info mb-0 justify-content-center">
                  
                  <div className="col-md-4 col-lg-2 d-flex " style={{}}> 
                    <div className="align-self-stretch box p-4 text-center" style={{borderRadius: '15px', background: '#388e3c'}} data-sal="slide-up" data-sal-duration="500" data-sal-easing="ease">
                      <div className="icon d-flex align-items-center justify-content-center" style={{marginBottom: '3px',width: '50px',height: '50px'}}>
                        <MdSupervisorAccount color="#ffffff" size="2em"/>
                      </div>
                      <h6 className="">Visita técnica</h6>
                    </div>
                  </div>

                  <div className="pt-5 d-none d-sm-none d-md-block" data-sal="slide-right" data-sal-duration="2000" data-sal-easing="ease">
                    <MdArrowForward
                        color="#33a4db"
                        size="2em"
                    /> 
                  </div>

                  <div className="col-md-4 col-lg-2 d-flex ">
                    <div className="align-self-stretch box p-4 text-center" style={{borderRadius: '15px',background: '#f9a825'}} data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease">
                      <div className="icon d-flex align-items-center justify-content-center" style={{marginBottom: '3px',width: '50px',height: '50px'}}>
                        <MdAssignment color="#ffffff" size="2em"/>
                      </div>
                      <h6 className="">Levantamiento de Información</h6>
              
                    </div>
                  </div>

                  <div className="pt-5 d-none d-sm-none d-md-none d-lg-block" data-sal="slide-right" data-sal-duration="2000" data-sal-easing="ease">
                    <MdArrowForward
                        color="#33a4db"
                        size="2em"
                    /> 
                  </div> 
                  
                  <div className="col-md-4 col-lg-2 d-flex ">
                    <div className="align-self-stretch box p-4 text-center" style={{borderRadius: '15px',background: '#ef6c00'}} data-sal="slide-up" data-sal-duration="1500" data-sal-easing="ease">
                      <div className="icon d-flex align-items-center justify-content-center" style={{marginBottom: '3px',width: '50px',height: '50px'}}>
                        <MdList color="#ffffff" size="2em"/>
                      </div>
                      <h6 className="">Dianóstico o Requerimientos</h6>
                    </div>
                  </div>

                  <div className="pt-5 d-none d-sm-none d-md-block" data-sal="slide-right" data-sal-duration="2000" data-sal-easing="ease">
                    <MdArrowForward
                        color="#33a4db"
                        size="2em"
                    /> 
                  </div>

                  <div className="col-md-4 col-lg-2 d-flex ">
                    <div className="align-self-stretch box p-4 text-center" style={{borderRadius: '15px',background: '#039be5' }} data-sal="slide-up" data-sal-duration="2000" data-sal-easing="ease">
                      <div className="icon d-flex align-items-center justify-content-center" style={{marginBottom: '3px',width: '50px',height: '50px'}}>
                        <MdCheck color="#ffffff" size="2em"/>
                      </div>
                      <h6 className="">Aplicación de solución</h6>
                    </div>
                  </div>

                </div>
                
              </div>
            </section>
                
        </Layout>
    )
}

export default CCTV

export const pageQuery = graphql`
  query FacByCCTV {

    image1: file(relativePath: { eq: "cctv_banner.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 1500) {
                ...GatsbyImageSharpFluid
            }
        }
    } 
   
    cctv: file(relativePath: { eq: "cctv.jpg" }) {
      childImageSharp {
          fluid(maxWidth: 1500) {
              ...GatsbyImageSharpFluid
          }
      }
    }

  }
`